<template>
  <div class="modal-wrapper" :class="{ open: isOpen }">
    <div class="justify--center va-row">
      <div class="flex">
        <div class="modal-container">
          <div class="speech-bubble">
            <div class="d-flex justify-content-start head">
              <i class="fa fa-times modal-close" @click="dismissModal"></i>
            </div>
            <div class="modal-content" :style="modalContentStyle">
              <slot></slot>
            </div>
          </div>
          <img
            v-if="inno"
            class="inno-ask"
            :src="require('@/assets/images/inno-popup.png')"
            alt="ASK INNO"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    isOpen: {
      type: Boolean,
      default: () => false
    },
    inno: {
      type: Boolean,
      default: () => false
    },
    modalContentStyle: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    dismissModal() {
      this.$emit('dismiss')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-div {
  display: inline-block;
}
.subscriptionmodal .modal-content {
  padding: 0 !important;
}
.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.9);
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s ease-in;
  .modal-container {
    position: relative;
    // overflow-y: auto !important;
    width: calc(100vw - 200px);

    // for mobile
    @media screen and (max-width: 768px) {
      width: calc(100vw - 100px);
    }

    // for large screens
    @media screen and (min-width: 1200px) {
      width: calc(100vw - 300px);
    }

    .inno-ask {
      position: absolute;
      right: -60px;
      transform: translateY(50px);
      transition: transform 0.3s ease-in-out;
    }
    .speech-bubble {
      max-height: calc(100vh - 200px);
      max-width: calc(100vw - 200px);
      overflow-y: auto;
      position: relative;
      margin-top: 100px;
      background: #ffffff;
      border-radius: 1.8rem;
      padding: 30px 20px;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.8);
      transform: translateY(-30px);
      opacity: 0;
      transition: all 0.3s ease-in-out;
      .modal-close {
        color: $blue;
        &:hover {
          cursor: pointer;
          color: $darkblue;
        }
      }
      .modal-content {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
      }
    }
  }
  &.open {
    opacity: 1;
    visibility: visible;
    .modal-container {
      .inno-ask {
        transform: translateY(0);
      }
      .speech-bubble {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}
</style>
